import React, { Component } from 'react'
import { MapApiLoaderHOC, InfoWindow } from 'react-bmapgl';
import Map from 'react-bmapgl/Map'
import Marker from 'react-bmapgl/Overlay/Marker'

class MapBd extends Component {
	render() {
		return (
			<div>
				<Map
					style={{ height: 500 }}
					center={{lng: 120.781675, lat: 31.294573}}
					enableScrollWheelZoom={true}
					zoom={14}
				>
					<Marker position={{lng: 120.781675, lat: 31.294573}} icon={'simple_red'} enableDragging/>
					<InfoWindow position={{lng: 120.781675, lat: 31.294573}}  title="苏州敏芯微电子技术股份有限公司">
						Tel：+86 512 62956055<br/>
						苏州工业园区金鸡湖大道99号NW- 09楼501室"
					</InfoWindow>
				</Map>
			</div>
		)
	}
}

export default MapApiLoaderHOC({ ak: 'kRChA0XbDC1zftlOgBczgAaD9au1gSf4' })(MapBd)
