import React, { Component } from 'react'
import abortUsPng from './../../common/image/abortUs.png'
import AbortUsCss from './index.module.css'
import { Row, Col, Image, Spin } from 'antd';
import LazyLoad from 'react-lazyload';


export default class AbortUsComponent extends Component {
	render() {
		return (
			<div className={AbortUsCss.abortDiv}>
				<Row>
					<Col lg={{ span: 11, offset: 1 }}>
						<div>
							<p className={AbortUsCss.heading}>ABOUT US</p>
							<p className={AbortUsCss.headingContent}>威海中宏微宇科技有限公司</p>
							<p className={AbortUsCss.contents}>中宏微宇物联网事业部成立于2021年3月，在母公司苏州敏芯微电子技术股份有限公司强大的MEMS传感技术基础上，结合未来万物互联模式的发展趋势，中宏专注于智慧城市监测产品开发，实现智慧养老，智慧市政，智慧消防等数字化应用场景建设。开发可视化智能监管平台，实现动态监测，实时预警和精准判断，助力客户更智能决策。中宏致力于把数字世界带入每个生活场景，辅助城市的整体数理化升级。</p>
						</div>
						<div>
							<div className={AbortUsCss.headingNum}>5</div>
							<div className={AbortUsCss.subheading}>
								<span style={{ fontWeight: "700" }}>+</span><br></br>全资分支机构
							</div>
						</div>
					</Col>
					<Col lg={{ span: 10, offset: 2 }}>
						<div>
							<LazyLoad
								scroll={true}
								height={100}
								once
								offset={-200}
								placeholder={<Spin size="large" />}
							>
								<Image
									// className={headerCss.carouselContent}
									style={{ width: "100%", height: "100%", position: "relative" }}
									preview={false}
									src={abortUsPng}
								/>
							</LazyLoad>
							<div className={AbortUsCss.contentImg}>
								<p style={{ fontSize: '1vw', lineHeight: '10px', textAlign: 'left' }}>智慧芯城市，领航未来科技</p>
							</div>
						</div>

					</Col>
				</Row>
			</div>
		)
	}
}
