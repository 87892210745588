import React, { Component } from 'react'
import ProductCss from './index.module.css'
import { Row, Col, Image } from 'antd';
import manholeCover from './../../common/image/manholeCover.jpg'
import fireControl from './../../common/image/fireControl.png'
import guard from './../../common/image/guard.png'
import { Link } from 'react-router-dom'

const productsList = [
	{ id: '3', title: '智慧守护', imageUrl: guard },
	{ id: '1', title: '智慧井盖位移监测器', imageUrl: manholeCover },
	{ id: '2', title: '智慧消防位移监测器', imageUrl: fireControl },
]

export default class ProductCenter extends Component {
	render() {
		return (
			<div className={ProductCss.productDiv}>
				<Row>
					<Col lg={{ span: 18, offset: 1 }}>
						<h1 className={ProductCss.productFont}>产品展示</h1>
					</Col>
				</Row>
				<Row justify="space-around" gutter={16} style={{ margin: '20px' }} wrap={true}>
					{
						productsList.map((productObj) => {
							return (
								<Col lg={{ span: 7, offset: 1 }} key={productObj.id}>
									<div className={ProductCss.postFormatContent}>
										{/* <Link to={`/productDetail/${productObj.id}`}>
										<Card
											hoverable
											cover={<img alt="example" src={productObj.imageUrl} />}
										>
											<Meta title={productObj.title} />
										</Card>
									</Link> */}
										<Link to={`/productDetail/${productObj.id}`}>
											<div className={ProductCss.postThumbnail}>
												<Image preview={false} src={productObj.imageUrl} style={{ width: "100%", height: "100%" }}></Image>
											</div>
											<div className={ProductCss.contentwrap}>
												<h1 className={ProductCss.entryTitle}>
													<p className={ProductCss.featuredImage}>
														{productObj.title}
													</p>
												</h1>
											</div>
										</Link>
									</div>
								</Col>
							)
						})
					}
				</Row>
				<div className='asdasdas' style={{ marginTop: "35vh" }}>
				</div>
				{/* <Row justify="space-around" gutter={16} style={{ margin: '20px' }}>

					{
						productsList.map((productObj) => {
							return (
								<Col span={7} key={productObj.id}>
									<Link to={`/productDetail/${productObj.id}`}>
										<Card
											hoverable
											cover={<img alt="example" src={productObj.imageUrl} />}
										>
											<Meta title={productObj.title} />
										</Card>
									</Link>
								</Col>
							)
						})
					}
				</Row> */}
			</div >
		)
	}
}
