import ProductDetailCss from './index.module.css'
import { Row, Col, Image } from 'antd';
import coverDetail from './../../common/image/manholeSolutionDetail.jpg'
import fireControlDetail from './../../common/image/fireSolutionDetail.jpg'
import guardDetail from './../../common/image/guardSolutionDetail.jpg'
import manholeCoverShow from './../../common/image/manholeSolutionShow.jpg'
import fireControlShow from './../../common/image/fireSolutionShow.jpg'
import guardShow from './../../common/image/guardSolutionShow.jpg'
import { useParams } from 'react-router-dom'

const productsData = [
  { id: '1', title: '智慧井盖解决方案', imageUrl: coverDetail, titleUrl1: manholeCoverShow },
  { id: '2', title: '智慧消火解决方案', imageUrl: fireControlDetail, titleUrl1: fireControlShow },
  { id: '3', title: '智慧守护解决方案', imageUrl: guardDetail, titleUrl1: guardShow },
]

export default function SolutionDetail() {
  let param = useParams();
  const { id } = param
  //let navigate = useNavigate();
  const findResult = productsData.find((detailObj) => {
    return detailObj.id === id
  })
  return (
    <div className={ProductDetailCss.productDiv}>
      <Row>
        <Col lg={{ span: 18 }}>
          <h1 className={ProductDetailCss.productTitleFont}>解决方案</h1>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col lg={{ span: 24, offset: 0 }}>
          <Image
            style={{ width: "100%", height: "100%" }}
            preview={false}
            src={findResult?.titleUrl1}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col lg={{ span: 18, offset: 1 }}>
          <h1 className={ProductDetailCss.productTitleFont}>产品详情</h1>
        </Col>
      </Row> */}
      <Row justify="space-around">
        <Col lg={{ span: 24 }}>
          {/* <h1 className={ProductDetailCss.productFont}>{findResult?.title}</h1> */}
          <Image
            style={{ width: "100%", height: "100%" }}
            preview={false}
            src={findResult?.imageUrl}
          />
        </Col>
      </Row>
    </div>
  )
}
