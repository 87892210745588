import React, { Component } from 'react';
import { Image } from 'antd';
import headerCss from './index.module.css';
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import Carouse1Img from './../../common/image/1.jpg'
import Carouse2Img from './../../common/image/2.jpg'
import Carouse3Img from './../../common/image/3.jpg'
import Carouse4Img from './../../common/image/4.jpg'

export default class CarouselComponent extends Component {
	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			arrows: true,
			autoplay: true,
		};
		return (
			<div>
				<Slider {...settings} className={headerCss.carouselDiv}>
					<div className={headerCss.carouselContent}>
						<Image
							className={headerCss.carouselImg}
							preview={false}
							src={Carouse1Img}
						/>
						<div style={{ position: "absolute", color: "#cef6ff", top: "60%", left: "10%" }}>
							<p style={{ fontSize: '2.9vw', lineHeight: '10px', textAlign: 'left' }}>感知和驱动智慧世界</p>
							<p style={{ fontSize: '1.8vw', lineHeight: '16px', textAlign: 'left', padding: "1vh 2vw 0px 0px" }}>iCity正在通过智能硬件、物联网技术以及适用行业和企业的综合智慧物联解决方案</p>
							<p style={{ fontSize: '1.8vw', lineHeight: '10px', textAlign: 'left' }}>加速世界向可持续互联的转变</p>
						</div>
					</div>

					<div className={headerCss.carouselContent}>
						<Image
							className={headerCss.carouselImg}
							preview={false}
							src={Carouse2Img}
						/>
						<div style={{ position: "absolute", top: "22%", left: "10%" }}>
							<p style={{ fontSize: '2.9vw', lineHeight: '10px', textAlign: 'left', color: '#CEF6FF' }}>智慧守护</p>
							<p style={{ fontSize: '1.8vw', lineHeight: '10px', textAlign: 'left', color: '#CEF6FF', paddingLeft: "10px" }}>时刻守护你关爱的TA</p>

							<p style={{ fontSize: '0.9vw', lineHeight: '10px', textAlign: 'left', color: "black", paddingTop: '23px', paddingLeft: '13px' }}>
								<Link to='/productDetail/3' style={{ color: "#E6F4F1" }}>进一步了解  {">"} </Link>
							</p>

						</div>
					</div>
					<div className={headerCss.carouselContent}>
						<Image
							className={headerCss.carouselImg}
							preview={false}
							src={Carouse3Img}
						/>
						<div style={{ position: "absolute", top: "65%", left: "61%" }}>
							<p style={{ fontSize: '2.9vw', lineHeight: '10px', textAlign: 'left', color: "black" }}>智慧井盖位移监测器</p>
							<p style={{ fontSize: '1.6vw', lineHeight: '10px', textAlign: 'left', color: "black", paddingLeft: "10px" }}>让脚下的安全插上智慧的翅膀</p>


							<p style={{ fontSize: '0.7vw', lineHeight: '10px', textAlign: 'left', color: "black", paddingTop: '23px', paddingLeft: '13px' }}>
								<Link to='/productDetail/1'>进一步了解 {">"} </Link>
							</p>
						</div>
					</div>
					<div className={headerCss.carouselContent}>
						<Image
							className={headerCss.carouselImg}
							preview={false}
							src={Carouse4Img}
						/>
						<div style={{ position: "absolute", top: "65%", left: "2%" }}>
							<p style={{ fontSize: '2.9vw', lineHeight: '10px', textAlign: 'left', color: "bluck" }}>智慧消防位移监测器</p>
							<p style={{ fontSize: '1.6vw', lineHeight: '10px', textAlign: 'left', color: "#322f2f", paddingLeft: "10px" }}>让一切突如其来变成未雨绸缪</p>
							<p style={{ fontSize: '0.7vw', lineHeight: '10px', textAlign: 'left', paddingTop: '23px', paddingLeft: '13px' }}>
								<Link to='/productDetail/2' style={{ color: "#fcf1ca" }}>进一步了解 {">"} </Link>
							</p>
						</div>
					</div>
				</Slider >
				{/* <RightOutlined style={{ color: 'black' }} /> */}
			</div >
		);
	}
}
