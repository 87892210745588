/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { Row, Col, Image } from 'antd';
import footerCss from './index.module.css'
import qrPng from './../../common/image/qr.png'
import police from './../../common/image/police.png'

export default class FooterComponent extends Component {
	render() {
		return (
			<div className={footerCss.footerDiv}>
				<Row>
					<Col lg={{ span: 18, offset: 1 }}>
						<span style={{ fontSize: "0.6rem" }}>
							<p><a href="http://www.beian.gov.cn" target="_blank">鲁ICP备2021041573号-1</a></p>
							<div style={{ marginTop: "0", marginBottom: "1em" }}><Image preview={false} src={police} style={{ width: "0.8rem" }}></Image><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003869" target="_blank">鲁公网安备32059002003869</a></div>
							<p>© 2021 - 2022 威海中宏微宇科技有限公司 All Right Reserved</p>
						</span>
					</Col>
					<Col lg={{ span: 2, offset: 3 }}>
						<Image
							// className={headerCss.carouselContent}
							style={{ width: "4.5rem", height: "100%" }}
							preview={false}
							src={qrPng}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}
