import React, { Component } from 'react'
import { MapApiLoaderHOC } from 'react-bmapgl';
import MapBd from './mapBd'
import ContactlCss from './index.module.css'
import { Row, Col } from 'antd';

class ContactComponent extends Component {
	render() {
		return (
			<div className={ContactlCss.ContacttDiv}>
				<Row>
					<Col lg={{ span: 18, offset: 1 }}>
						<h1 className={ContactlCss.ContactlFont}>联系我们</h1>
					</Col>
				</Row>
				<Row className={ContactlCss.mapBd}>
					<Col lg={{ span: 22, offset: 1 }}>
						<MapBd></MapBd>
					</Col>
				</Row>
				<Row>
					<Col lg={{ span: 22, offset: 1 }}>
						<h1 className={ContactlCss.ContactlFont}>联系信息</h1>
						<p>地址：苏州工业园区金鸡湖大道99号NW- 09楼501室</p>
						<p>销售热线：+86 512 62956055 - 8051/8057</p>
						<p>公司电话：+86 512 62956055　　传真：+86 512 62956056</p>
						<p>邮箱：info@memsensing.com</p>
					</Col>
				</Row>
			</div>
		)
	}
}

export default MapApiLoaderHOC({ ak: 'LBIAE6q06L3MfbkuV6TqbaZ9MDZA4kkF' })(ContactComponent)
