import React, { FC } from 'react'
import './App.less'
import HeaderComponet from './components/HeaderComponet'
import FooterComponent from "./components/FooterComponent";
import Home from "./pages/Home";
import ProductCenter from "./pages/ProductCenter"
import ProductDetail from "./pages/ProductDetail"
import SolutionDetail from "./pages/SolutionDetail"
import Solution from "./pages/Solution"
import Recruit from "./pages/Recruit"
import Contact from "./pages/Contact"
import { Route, Routes, Navigate } from 'react-router-dom'

const App: FC = () => (
  <div className="App">
    <HeaderComponet></HeaderComponet>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="productCenter" element={<ProductCenter />} />
      <Route path="solution" element={<Solution />} />
      <Route path="productDetail/:id" element={<ProductDetail />} />
      <Route path="solutionDetail/:id" element={<SolutionDetail />} />
      <Route path="recruit" element={<Recruit />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <FooterComponent ></FooterComponent>
  </div>
);

export default App;