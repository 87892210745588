import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import headerCss from './index.module.css'
import { Link } from 'react-router-dom'

const { Header } = Layout;

const menuList = [
	{ path: '/', title: '首页' },
	{ path: '/productCenter', title: '产品展示' },
	{ path: '/solution', title: '解决方案' },
	{ path: '/recruit', title: '招聘信息' },
	{ path: '/contact', title: '联系我们' },
]

export default class HeaderComponet extends Component {
	render() {
		return (
			<div style={{ position: 'fixed', zIndex: '999', width: '100%' }}>
				<Header className={headerCss.headerDiv} style={{ background: 'rgb(255, 255, 255,0.3)' }}>
					<div className={headerCss.logo} />
					<Menu className={headerCss.menuStyle} style={{ borderBottom: "none" }} mode="horizontal" defaultSelectedKeys={[window.location.pathname]}>
						{
							menuList.map((menuItem) => {
								return (
									<Menu.Item key={menuItem.path}>
										<Link to={menuItem.path}>{menuItem.title}</Link>
									</Menu.Item>
								)
							})
						}
						<Menu.Item key='control'>
							<a href="https://admin.q-mote.com" target="_blank" rel="noreferrer">登录</a>
						</Menu.Item>
					</Menu>
				</Header>
			</div>
		)
	}
}
