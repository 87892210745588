import React, { Component } from 'react'
import culture1 from './../../common/image/culture1.jpg'
import culture2 from './../../common/image/culture2.jpg'
import culture3 from './../../common/image/culture3.jpg'
import culture4 from './../../common/image/culture4.jpg'
import AbortUsCss from './index.module.css'
import { Row, Col, Image, Spin } from 'antd';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload'


export default class CultureComponent extends Component {
	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: false,
			autoplay: true
		};
		return (
			<div style={{ padding: '1vw 0px 1.4vw 0px', backgroundColor: "#f5f5f7" }}>
				<Row>
					<Col lg={{ span: 11, offset: 1 }}>
						<Slider {...settings}>
							<div>
								<LazyLoad
									scroll={true}
									offset={-100}
									height={100}
									once
									placeholder={<Spin size="large" />}
								>
									<Image
										// className={headerCss.carouselContent}
										style={{ width: "98%", height: "90%", textAlign: 'center' }}
										preview={false}
										src={culture1}
									/>
								</LazyLoad>
							</div>
							<div>
								<LazyLoad
									scroll={true}
									offset={-100}
									height={100}
									once
									placeholder={<Spin size="large" />}
								>
									<Image
										// className={headerCss.carouselContent}
										style={{ width: "98%", height: "90%", textAlign: 'center' }}
										preview={false}
										src={culture2}
									/>
								</LazyLoad>
							</div>
							<div>
								<LazyLoad
									scroll={true}
									offset={-100}
									height={100}
									placeholder={<Spin size="large" />}
									once
								>
									<Image
										// className={headerCss.carouselContent}
										style={{ width: "98%", height: "90%", textAlign: 'center' }}
										preview={false}
										src={culture3}
									/>
								</LazyLoad>
							</div>
							<div>
								<LazyLoad
									scroll={true}
									offset={-100}
									height={100}
									once
									placeholder={<Spin size="large" />}
								>
									<Image
										// className={headerCss.carouselContent}
										style={{ width: "98%", height: "90%", textAlign: 'center' }}
										preview={false}
										src={culture4}
									/>
								</LazyLoad>
							</div>
						</Slider>
					</Col>
					<Col lg={{ span: 4, offset: 8 }}>
						<p className={AbortUsCss.heading}>OUR CULTURE</p>
						<p className={AbortUsCss.headingContent}>我们的文化</p>
					</Col>
				</Row>
			</div>
		)
	}
}
