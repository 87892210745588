import React, { Component } from 'react'
import CarouselComponent from './../../components/CarouselComponent'
import AbortUsComponent from '../AbortUsComponent'
import CultureComponent from './../../components/CultureComponent'

export default class Home extends Component {
	render() {
		return (
			<div>
				<CarouselComponent></CarouselComponent>
				<AbortUsComponent></AbortUsComponent>
				<CultureComponent></CultureComponent>
			</div>
		)
	}
}
