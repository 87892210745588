import ProductDetailCss from './index.module.css'
import { Row, Col, Image } from 'antd';
import coverDetail from './../../common/image/coverDetail.jpg'
import fireControlDetail from './../../common/image/fireControlDetail.jpg'
import guardDetail from './../../common/image/guardDetail.jpg'
import manholeCoverShow1 from './../../common/image/manholeCover-show1.png'
import manholeCoverShow2 from './../../common/image/manholeCover-show2.png'
import fireControlShow1 from './../../common/image/fireControl-show1.png'
import fireControlShow2 from './../../common/image/fireControl-show2.png'
import guardShow1 from './../../common/image/guard-show1.png'
import guardShow2 from './../../common/image/guard-show2.png'
import { useParams } from 'react-router-dom'

const productsData = [
  { id: '1', title: '智慧井盖位移监测器', imageUrl: coverDetail, titleUrl1: manholeCoverShow1, titleUrl2: manholeCoverShow2 },
  { id: '2', title: '智慧消火位移监测器', imageUrl: fireControlDetail, titleUrl1: fireControlShow1, titleUrl2: fireControlShow2 },
  { id: '3', title: '智慧守护', imageUrl: guardDetail, titleUrl1: guardShow1, titleUrl2: guardShow2 },
]

export default function ProductDetail() {
  let param = useParams();
  const { id } = param
  //let navigate = useNavigate();
  const findResult = productsData.find((detailObj) => {
    return detailObj.id === id
  })
  return (
    <div className={ProductDetailCss.productDiv}>
      <Row>
        <Col lg={{ span: 18, offset: 1 }}>
          <h1 className={ProductDetailCss.productTitleFont}>产品中心</h1>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col lg={{ span: 10, offset: 1 }}>
          <Image
            style={{ width: "100%", height: "100%" }}
            preview={false}
            src={findResult?.titleUrl1}
          />
        </Col>
        <Col lg={{ span: 10, offset: 1 }}>
          <Image
            style={{ width: "100%", height: "100%" }}
            preview={false}
            src={findResult?.titleUrl2}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 18, offset: 1 }}>
          <h1 className={ProductDetailCss.productTitleFont}>产品详情</h1>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col lg={{ span: 22 }}>
          <h1 className={ProductDetailCss.productFont}>{findResult?.title}</h1>
          <Image
            style={{ width: "100%", height: "100%" }}
            preview={false}
            src={findResult?.imageUrl}
          />
        </Col>
      </Row>
    </div>
  )
}
