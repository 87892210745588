import React, { Component } from 'react'
import ProductDetailCss from './index.module.css'
import { Row, Col, Collapse, Image } from 'antd';
import recruitImg from './../../common/image/recruit.jpg'
const { Panel } = Collapse;
export default class RecruitComponent extends Component {
	render() {
		return (
			<div className={ProductDetailCss.productDiv}>
				<Row>
					<Col lg={{ span: 24 }}>
						<Image src={recruitImg} preview={false}></Image>
					</Col>
					<Col lg={{ span: 18, offset: 1 }}>
						<h1 className={ProductDetailCss.productTitleFont}>欢迎加入拥有充满激情、追求卓越的简新团队</h1>
					</Col>
				</Row>
				<Row justify="space-around">
					<Col lg={{ span: 22 }}>
						<Collapse defaultActiveKey={['1']}>
							<Panel header="GO后端开发工程师" key="1">
								<p>岗位要求：<br />
									1. 精通Go开发语言，具有丰富的开发、调试和性能优化等经验，有C++或大数据开发经验者优先；<br />
									2. 了解docker、kubernetes等容器技术；<br />
									3. 熟悉 Git/GitHub 使用；<br />
									4. 熟练使用 Mysql、postgres、Redis，kafka 有其他 NoSQL 经验优先；<br />
									5. 扎实的软件知识结构（操作系统、数据结构、网络、数据库系统等）；<br />
									6. 具备较强的团队意识、沟通能力及责任心，具备良好的学习能力，具备良好的心态；<br />
									7. 具备优秀的沟通能力，解决能力。优先考虑曾在多文化团队工作过的从业者；<br />
									8. 英文读写能作为工作语言。<br />
								</p>
							</Panel>
							<Panel header="前端开发工程师" key="2">
								<p>岗位要求： <br />1.负责移动前端相关开发工作，能够熟练手写html5、css3、javascript 。 <br />
									2.负责与UI设计师、UE、软件开发工程师和产品经理协作，高效高质完成相关开发工作 <br />
									3.能够快速学习并使用团队内部JS框架、组件和前端规范，进行产品开发 <br />
									4.对移动前端项目进行性能优化 <br />
									任职资格：
									<br />1.计算机相关专业，两年以上移动前端开发经验; <br />
									2.熟练掌握HTML5JSCSS等前端技术；<br />
									3.熟练掌握Vue、Rect等至少一个前端主流框架，并有相关项目开发经验；<br />
									4.熟悉微信小程序开发及相关API及框架;<br />
									5.熟悉前后端交互流程，有nodeJS开发经验优先；<br />
									6.对移动端Web技术主流框架思想和新的前端思想要有所认识和关注；<br />
									7.良好的学习能力、团队协作能力和沟通能力；善于思考，能独立分析和解决问题；</p>
							</Panel>
							<Panel header="嵌入式开发工程师" key="3">
								<p>岗位要求：<br />
									1、根据项目要求提出实现方案，完成嵌入式系统硬件设计及调试；<br />
									2、负责电子电路和模块的调试及模块PCB原理图和版图设计，负责BOM表制定，并跟进SMT生产进度，完成硬件调试；<br />
									3、解决嵌入式系统硬件各类技术问题，包括产品量产时遇到的可靠性问题；<br />
									4、根据产品需求，制定产品硬件方案，并进行元器件选型与评估；<br />
									5、协助完成EMC、安规等产品相关认证测试工作；<br />
									6、配合后期的验证、过程确认、注册检测、注册、临床等技术支持；<br />
									7、完成量产支持，产品优化，设计变更；<br />
									8、协助专利工程师完成专利撰写；<br />
									9、完成领导交予的其他任务。 <br />
									任职资格: <br />
									1、本科及以上学历，电子信息相关专业，有机器人控制主板或安全控制板等嵌入式系统开发经验者优先；<br />
									2、3年以上医疗器械或相关工作经验，精通数字电路分析及设计，转换电路、串口电路的设计；<br />
									3、熟悉使用Cadence等电路板设计软件；<br />
									4、具有ARM系列、TI公司系列或Altera、Xilinx公司的FPGA和CPLD系列的开发经验；<br />
									5、熟悉多层电路板设计规则，了解EMI相关知识，能够指导PCB板卡的布局布线，具备解决产品中的电磁兼容问题的能力；具备较强的动手能力，能够熟练使用示波器、万用表、电烙铁等常用调试、焊接设备；<br />
									6、熟悉IEC60601标准者优先；<br />
									7、为人正直、诚实，善于沟通、观察敏锐，工作认真负责，严谨细致，有良好的团队合作精神，有一定抗压能力和奋斗精神。</p>
							</Panel>
						</Collapse>
					</Col>
				</Row>
			</div>
		)
	}
}
